import {IRoute} from 'app/blocks/model/route.model';
import {ICustomer} from 'app/blocks/model/customer.model';
import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {IDeliveryPoint} from 'app/blocks/model/delivery-point.model';

export interface IDriverAccessCodes extends IBaseEntity {
    id?: number;
    customer?: ICustomer;
    deliveryPoint?: IDeliveryPoint;
    route?: IRoute;
    date?: string;
    authCode?: string;
    used?: boolean;
}

export class DriverAccessCodes extends BaseEntity implements IDriverAccessCodes {
    id?: number;
    customer?: ICustomer;
    deliveryPoint?: IDeliveryPoint;
    date?: string;
    authCode?: string;
    used?: boolean;
    route?: IRoute;

    constructor(driverAccessCode?) {
        super();
        if (driverAccessCode) {
            this.id = driverAccessCode.id;
            this.customer = driverAccessCode.customer;
            this.deliveryPoint = driverAccessCode.deliveryPoint;
            this.route = driverAccessCode.route;
            this.date = driverAccessCode.date;
            this.authCode = driverAccessCode.authCode;
            this.used = driverAccessCode.used;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): DriverAccessCodes {
        return new DriverAccessCodes(this);
    }
}
